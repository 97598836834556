@import 'style/colors';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

html {
  height: 100%;
  // stops body scroll feedback on chrome
  overflow: hidden;
}

body {
  background: $surfaceBackground;
  font-family: sans-serif;
  color: $surfaceColor;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

div#root {
  height: 100%;
  overflow: hidden;
}

h1 {
  font-weight: 100;
  font-size: 2.6rem;
  text-align: center;
}

h2 {
  font-weight: 100;
  font-size: 2rem;
}

p {
  text-align: center;
  margin: 0 0 1.2rem;
}

svg {
  fill: currentColor;
}

input {
  font-size: 1rem;
}
