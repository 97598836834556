@import '@alphaflow/style/build/standardLightPresentation';

.ViewClassroomAssignmentSubmissionsPage {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > .__content {
    flex: 1;
    min-height: 0;
  }

  & > .__title {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    padding: 12px 24px;
    border-bottom: $border;
    justify-content: space-between;
    & > .__header {
      margin: 0;
    }
  }
}
