@import '@alphaflow/style/build/standardLightPresentation';

.App {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  overflow: auto;

  & > .__toolbar {
    display: flex;
    flex: 0 0 auto;
    padding: 12px;
    justify-content: flex-end;
    align-self: stretch;
    border-bottom: $border;
  }
  & > .__content {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow: auto;
  }
}

svg.FloIcon,
svg.icon {
  display: inline-block;
  opacity: 1;
  margin: 0 0.25rem -0.125em 0;
  fill: currentColor;
  height: 1em;
  width: 1em;
}

._divider {
  min-width: 300px;
}
._segment {
  width: 400px;
}
